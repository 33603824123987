<template>
  <div class="foot">
    <div class="home_foot">
      <el-row style="color:white">
        <el-col :span="2" :push="3">
          <ul class="home_foot_ul">
            <li>关于我们</li>
            <li @click=""><router-link to="/companyIntro">公司简介</router-link></li>
            <li @click=""><router-link to="/companyHonor">公司荣誉</router-link></li>
            <li @click=""><router-link to="/contactMe">联系我们</router-link></li>
            <li @click=""><router-link to="/journalism">新闻</router-link></li>
          </ul>
        </el-col>
        <el-col :span="2" :push="4">
          <ul>
            <li>服务</li>
            <li @click=""><router-link to="/cloudServe">云存储服务</router-link></li>
            <li @click=""><router-link to="/technology">技术支持</router-link></li>
            <li><router-link to="/software">软件下载</router-link></li>
            <li @click=""><router-link to="/afterSale">售后政策</router-link></li>
          </ul>
        </el-col>
        <el-col :span="2" :push="5">
          <ul>
            <li>合作伙伴</li>
            <li @click=""><router-link to="/agency">加盟代理</router-link></li>
            <li @click=""><router-link to="/authorization">授权说明</router-link></li>
            <li><router-link to="/twiceDevelop">二次开发</router-link></li>
            <li @click=""><router-link to="/developTeamwork">制定开发合作</router-link></li>
          </ul>
        </el-col>


        <el-col :span="4" :push="9">
          <ul class="ul_right">
            <li>关注我们</li>
            <li><img src="../../public/images/底部导航/智驹app下载地址.jpg" width="140px" height="140px" @click=""></li>
            <li>热线: 400-602-9990</li>
          </ul>
        </el-col>
      </el-row>
      <el-row>
      <el-col :span="2" :push="3">
        <div>友情连接</div>
      </el-col>
      </el-row>
<!--      <div>-->
<!--        <div>友情链接</div>-->
<!--      </div>-->

      <div class="foot_bottom">
        <div class="style_top">
          Copyright © 2018-2021 深圳市智驹科技有限公司 粤ICP备12056938号-7
          <a href="https://www.cnzz.com/stat/website.php?web_id=1259561122">
            <img style="padding-left: 10px"src="https://icon.cnzz.com/img/pic1.gif"></a>
          <a href="https://szcert.ebs.org.cn/48fd5d47-f3dc-4911-9015-01df59d0dc34">
            <img style="padding-left: 10px"
                 width="24px" height="34px"
                 src="../../public/images/首页/govlcon.gif">
          </a>
        </div>
        <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot"
}
</script>

<style scoped>
.foot{
  margin-top: 15px;
}
.home_foot {
  /*background: -webkit-linear-gradient(#FEC168, #F0694B);*/
  color: white;
  height: 370px;
  width: 100%;
  background: #f60810;
  overflow: hidden;
  padding-bottom: 0px;
}

.home_foot ul {
  list-style: none;
}

.home_foot ul li:nth-child(1) {
  font-size: 18px;
  cursor: auto;
  padding-bottom: 10px;
}

.home_foot ul li:nth-child(1):hover {
  color: white;
}

.home_foot ul li {
  padding-top: 15px;
  font-size: 12px;

  cursor: pointer;
}

.home_foot ul li:hover {
  color: #019BFF;
}

.foot_bottom {
  margin-top: 30px;
  color: #ffffff;
  text-align: center;
  clear: both;
  width: 100%;
  overflow: hidden;
  border-top: 1px #ffffff solid;
}
a{
  color: white;
  text-decoration:none
}
a:hover{
  color: #019BFF;
}
.style_top{
  line-height: 70px;
}
</style>