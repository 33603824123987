import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'jquery'

Vue.config.productionTip = false
Vue.use(ElementUI);
//跳转后显示顶部
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
