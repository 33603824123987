<template>
  <div id="app">

    <div>
      <ul class="side-pannel">
        <el-popover
            placement="left"
            close-delay="10"
            trigger="hover"
            content="点我，回到顶部！"
        >
          <li slot="reference"> <img src="../public/images/首页/top.png" v-if="btnFlag" class="go-top"   @click="backTop"> </li>
        </el-popover>
      </ul>
    </div>

<!--    <img src="../public/images/首页/top.png" v-if="btnFlag" class="go-top"   @click="backTop">-->

    <zjMenu></zjMenu>
    <router-view/>
    <foot></foot>
  </div>
</template>
<script>
import zjMenu from '../src/components/zjMenu.vue'
import foot from '../src/components/foot.vue'

export default {
  components: {
    foot,
    zjMenu
  },
  data() {
    return{
      btnFlag:false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 200) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    }
  }
}
</script>
<style scoped>
/*html, body { scroll-behavior:smooth;}*/
html,body{
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Lantinghei SC", "Open Sans", Arial, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "STHeiti", "WenQuanYi Micro Hei", SimSun, sans-serif;

}
.head_top{
  margin-top: 60px;
}
.go-top{
  /*display: block;*/
  /*overflow: scroll;*/
  /*position: fixed;*/
  /*top: 1000px;*/
  /*left:800px;*/
  /*z-index:99999;*/
  /*width: 100px;*/
  /*height: 100px;*/
  /*background: red;*/
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 99999;

}
.go-top:hover{
  cursor: pointer;
}
.side-pannel {
  list-style: none;
  position: fixed;
  width: 30px;
  bottom: 55px;
  right: 33px;
  z-index: 1000;
}
</style>
