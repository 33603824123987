<template>
  <div>
    <el-menu  :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item class="sad" index="/" @click="home">
        <img src="../../public/images/首页/智驹LOGO.jpg" lazy width="100%" height="58px">
      </el-menu-item>
      <el-submenu index="1">
        <template slot="title">WiFi摄像机</template>
        <el-menu-item  style="height:210px;">
          <div class="style_head">
            <div class="style_head_a" @click="indoor">
              <img src="../../public/images/首页/WiFi摄像机/CS24S室内摄像机.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">室内摄像机</div>
            </div>
            <div class="style_head_a" @click="outdoor">
              <img src="../../public/images/首页/WiFi摄像机/室外wifi机 导航Z60.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">室外摄像机</div>
            </div>
          </div>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="2">
        <template slot="title">4G摄像机</template>
        <el-menu-item  style="height:210px;">
          <div class="style_head">
            <div class="style_head_a" @click="FGIndoor">
              <img src="../../public/images/首页/4G摄像机/4g室内摄像机.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">室内摄像机</div>
            </div>
            <div class="style_head_a" @click="FGOutdoor">
              <img src="../../public/images/首页/4G摄像机/CG52.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">室外摄像机</div>
            </div>
          </div>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="3">
        <template slot="title">低功耗摄像机</template>
        <el-menu-item  style="height:210px;">
          <div class="style_head">
            <div class="style_head_a" @click="dghIndoor">
              <img src="../../public/images/首页/低功耗摄像机/低功耗摄像机.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">室内摄像机</div>
            </div>
            <div class="style_head_a">
              <img src="../../public/images/首页/低功耗摄像机/CB53.png" width="140px" height="140px" @click="">
              <div style="text-align: center">室外摄像机</div>
            </div>
            <div class="style_head_a" @click="videoDoorbell">
              <img src="../../public/images/首页/智能单品/可视门铃.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">视频门铃</div>
            </div>
          </div>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="4">
        <template slot="title">智能单品</template>
        <el-menu-item  style="height:210px;">
          <div class="style_head">
<!--            <div class="style_head_a" @click="">-->
<!--              <img src="../../public/images/首页/智能单品/可视门铃.jpg" width="140px" height="140px" @click="">-->
<!--              <div style="text-align: center">视频门铃</div>-->
<!--            </div>-->
            <div class="style_head_a" @click="securityAccessories">
              <img src="../../public/images/首页/智能单品/安防配件.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">安防配件</div>
            </div>
          </div>
        </el-menu-item>
      </el-submenu>


      <el-submenu index="8">
        <template slot="title">工程摄像机</template>
        <el-menu-item  style="height:210px;">
          <div class="style_head">
            <div class="style_head_a">
              <img src="../../public/images/首页/工程机/智驹首页导航工程机小图.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">室外机</div>
            </div>
<!--            <div class="style_head_a">-->
<!--              <img src="../../public/images/首页/智能单品/智能摄像泛光灯.jpg" width="140px" height="140px" @click="">-->
<!--              <div style="text-align: center">智能摄像泛光灯</div>-->
<!--            </div>-->
          </div>
        </el-menu-item>
      </el-submenu>


      <el-submenu index="5">
        <template slot="title">APP</template>
        <el-menu-item   route='/appIntroduce' style="height:210px;">
          <div class="style_head">
            <div class="style_head_b" @click="appIntroduce">
              <img src="../../public/images/首页/APP/智驹APP.png" width="140px" height="140px" @click="appIntroduce">
              <div style="text-align: center">智驹</div>
            </div>

          </div>
        </el-menu-item>
      </el-submenu>


      <el-submenu index="6">
        <template slot="title">服务</template>
        <el-menu-item  style="height:210px;">
          <div class="style_head">
            <div class="style_head_a" @click="technology">
              <img src="../../public/images/首页/服务/技术支持.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">技术支持</div>
            </div>
            <div class="style_head_a" @click="software">
              <img src="../../public/images/首页/服务/软件下载.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">软件下载</div>
            </div>
          </div>
        </el-menu-item>
      </el-submenu>


      <el-submenu index="7">
        <template slot="title">加盟</template>
        <el-menu-item  style="height:210px;">
          <div class="style_head">
            <div class="style_head_b" @click="agency">
              <img src="../../public/images/首页/商务合作/项目合作.jpg" width="140px" height="140px" @click="">
              <div style="text-align: center">代理加盟</div>
            </div>

          </div>
        </el-menu-item>
      </el-submenu>

      <el-menu-item index="9" @click="contactMe">联系我们</el-menu-item>
    </el-menu>


    <!--    <div   @mouseover="zxc = true" @mouseleave="zxc = false">123113</div>-->
    <!--    <div class="style_back" v-show="zxc">-->
    <!--      13-->
    <!--    </div>-->


  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "zjMenu",
  data: function () {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      zxc: false
    }
  },
  computed: {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    home(){
      this.$router.push('/')
    },
    //app
    appIntroduce(){
      this.$router.push('/appIntroduce')
    },
    //wifi室外
    outdoor(){
      this.$router.push('/outdoor')
    },
    //wifi室内
    indoor(){
      this.$router.push('/indoor')
    },
    //联系我们
    contactMe(){
      this.$router.push('/contactMe')
    },
    //技术支持
    technology(){
      this.$router.push('/technology')
    },
    //软件下载
    software(){
      this.$router.push('/software')
    },
    //代理加盟
    agency(){
      this.$router.push('/agency')
    },
    //智能安防
    securityAccessories(){
      this.$router.push('/securityAccessories')
    },
    //低功耗室内
    dghIndoor(){
      this.$router.push('/dghIndoor')
    },
    //视频门铃
    videoDoorbell(){
      this.$router.push('/videoDoorbell')
    },
    //4G室外
    FGOutdoor(){
      this.$router.push('/4GOutdoor')
    },
    //4G室内
    FGIndoor(){
      this.$router.push('/4GIndoor')
    },

  }

}
</script>

<style scoped>
/* 点击出来的下划线进行隐藏 */
/deep/.el-menu-item.is-active {
  border-bottom: none !important;
  color: rgba(0, 0, 0, 0);
}

/* 整体的下划线进行隐藏 */
.el-menu.el-menu--horizontal {
  border-bottom: none;
}

/* 导航栏的间距等样式 */
.el-menu-item {
  padding: 0px 30px;
  font-size: 14px;
}

/* 点击出来的下划线动效残留进行隐藏 */
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}

/* 点击以后的背景色进行隐藏 */
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0);
}

/deep/ .is-opened {
  /*width: 100%;*/
}

.el-menu-demo {
  /*background-color:rgba(0,0,0,0);*/
  /*margin-left: 20.54%;*/
  width: 100%;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  padding-left: 15%;
}

.style_head {
  display: flex;
}

.style_head_a {
  padding: 10px;
  color: #F60810;
}
.style_head_a:hover {
  color: #DA0810;
}

.style_head_b {
  padding: 25px;
  color: #F60810;
}
.style_head_b:hover{
  color: #DA0810;
}
</style>