import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  // 头部导航
  {
    path: '/indoor',
    name: 'indoor',
    component: () => import('../views/头部导航/wifi摄像机/室内摄像机/index')
  },
  {
    path: '/outdoor',
    name: 'outdoor',
    component: () => import('../views/头部导航/wifi摄像机/室外摄像机/index')
  },
  {
    path: '/4GOutdoor',
    name: '4GOutdoor',
    component: () => import('../views/头部导航/4G摄像机/室外摄像机/index')
  },
  {
    path: '/4GIndoor',
    name: '4GIndoor',
    component: () => import('../views/头部导航/4G摄像机/室内摄像机/index')
  },
  {
    path: '/securityAccessories',
    name: 'securityAccessories',
    component: () => import('../views/头部导航/智能单品/安防配件/index')
  },
  {
    path: '/dghIndoor',
    name: 'dghIndoor',
    component: () => import('../views/头部导航/低功耗摄像机/室内摄像机/index')
  },
  {
    path: '/videoDoorbell',
    name: 'videoDoorbell',
    component: () => import('../views/头部导航/低功耗摄像机/视频门铃/index')
  },
  {
    path:'/appIntroduce',
    name:'appIntroduce',
    component: () => import('../views/头部导航/APP')
  },
    // 底部------------关于我们
  {
    path: '/companyIntro',
    name: 'companyIntro',
    component: () => import('../views/底部/关于我们/公司简介')
  },
  {
    path:'/companyHonor',
    name:'companyHonor',
    component: () => import('../views/底部/关于我们/公司荣誉')
  },
  {
    path:'/journalism',
    name:'journalism',
    component: () => import('../views/底部/关于我们/新闻')
  },
  {
    path:'/contactMe',
    name:'contactMe',
    component: () => import('../views/底部/关于我们/联系我们')
  }
  // 底部------------服务
  ,
  {
    path:'/cloudServe',
    name:'cloudServe',
    component: () => import('../views/底部/服务/云存储服务')
  } ,
  {
    path:'/afterSale',
    name:'afterSale',
    component: () => import('../views/底部/服务/售后政策')
  } ,
  {
    path:'/software',
    name:'software',
    component: () => import('../views/底部/服务/软件下载')
  } ,
  {
    path:'/technology',
    name:'technology',
    component: () => import('../views/底部/服务/技术支持')
  }
  // 底部------------合作伙伴
  ,
  {
    path:'/twiceDevelop',
    name:'twiceDevelop',
    component: () => import('../views/底部/合作伙伴/二次开发')
  }
  ,
  {
    path:'/developTeamwork',
    name:'developTeamwork',
    component: () => import('../views/底部/合作伙伴/制定开发合作')
  }
  ,
  {
    path:'/agency',
    name:'agency',
    component: () => import('../views/底部/合作伙伴/加盟代理')
  }
  ,
  {
    path:'/authorization',
    name:'authorization',
    component: () => import('../views/底部/合作伙伴/授权说明')
  }
  // 技术支持（technical support）------------室内网络摄像相机(indoor)
  ,
  {
    path:'/tsindoorC48Q',
    name:'tsindoorC48Q',
    component: () => import('../views/技术支持/室内网络摄像机/C48Q')
  }
  // wifi摄像机  设备详情
  ,
  {
    path: '/CS48Q',
    name: 'CS48Q',
    component: () => import('../views/详情/wifi摄像机/室内云台摄像机（400万像素）/CS48Q')
  },
  {
    path: '/Z86Q',
    name: 'Z86Q',
    component: () => import('../views/详情/wifi摄像机/室外云台摄像机（500万像素）/Z86Q')
  }
  ,
  {
    path: '/Z60Q',
    name: 'Z60Q',
    component: () => import('../views/详情/wifi摄像机/室外云台摄像机（500万像素）/Z60Q')
  }
  ,
  {
    path: '/Z85-X18',
    name: 'Z85-X18',
    component: () => import('../views/详情/wifi摄像机/室外云台摄像机（500万像素）/Z85-X18')
  }
  ,
  {
    path: '/Z60',
    name: 'Z60',
    component: () => import('../views/详情/wifi摄像机/室外云台摄像机（300万像素）/Z60')
  }
  ,
  {
    path: '/Z82',
    name: 'Z82',
    component: () => import('../views/详情/wifi摄像机/室外云台摄像机（300万像素）/Z82')
  }
  ,
  {
    path: '/Z86',
    name: 'Z86',
    component: () => import('../views/详情/wifi摄像机/室外云台摄像机（300万像素）/Z86')
  },
  {
    path: '/Z71',
    name: 'Z71',
    component: () => import('../views/详情/wifi摄像机/室内机详情（200万像素）/Z71')
  },
  {
    path: '/Z76Q',
    name: 'Z76Q',
    component: () => import('../views/详情/wifi摄像机/室内机详情（500万像素）/Z76Q')
  },
  {
    path: '/Z81',
    name: 'Z81',
    component: () => import('../views/详情/wifi摄像机/室外机详情页（200万像素）/Z81')
  },
  //4G摄像头  室内  设备详情
  {
    path: '/ZG81',
    name: 'ZG81',
    component: () => import('../views/详情/4G摄像机/室外机/ZG81')
  },
  //4G摄像头  室外  设备详情
  {
    path: '/ZB52',
    name: 'ZB52',
    component: () => import('../views/详情/4G摄像机/室内机/ZB52')
  },
  //智能单品 安防配件 设备详情
  {
    path: '/Z61Q',
    name: 'Z61Q',
    component: () => import('../views/详情/智能单品/安防配件/Z61Q')
  },
  {
    path: '/Z61',
    name: 'Z61',
    component: () => import('../views/详情/智能单品/安防配件/Z61')
  },
  //低功耗摄像机 室内摄像机 设备详情
  {
    path: '/ZB53',
    name: 'ZB53',
    component: () => import('../views/详情/低功耗摄像机/室内摄像机/ZB53')
  },
  //低功耗摄像机 视频门铃 设备详情
  {
    path: '/DB4',
    name: 'DB4',
    component: () => import('../views/详情/低功耗摄像机/视频门铃/DB4')
  },

  //--------------------新闻===================={
  {
    path: '/journalism1',
    name: 'journalism1',
    component: () => import('../views/底部/新闻详情/用什么来记录孩子的真善美')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
