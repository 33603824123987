<template>
  <div class="home head_top kuan">
<!--    <zjMenu></zjMenu>-->

    <!--    轮播-->
    <el-carousel :interval="4000" :height="bannerHeight+'px'">
      <el-carousel-item>
        <img src="../../public/images/首页/轮播/首页公司简介1.jpg" width="100%" height="100%" @click="">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/首页/轮播/智驹首页轮播图Z86---2.jpg" width="100%" height="100%" @click="">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/首页/轮播/智驹首页轮播图Z82---3.jpg" width="100%" height="100%" @click="">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/首页/轮播/智驹首页轮播图Z61---4.jpg" width="100%" height="100%" @click="">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/首页/轮播/智驹首页轮播图Z60---5.jpg" width="100%" height="100%" @click="">
      </el-carousel-item>
    </el-carousel>

<!--        content 公司简介-->
<!--    <img src="../../public/images/首页/轮播/首页公司简介1.jpg" width="100%" height="100%"  @click="companyIntro">-->

    <!--    content1 室内-->
    <img src="../../public/images/首页/轮播/室内机.jpg" width="100%" height="100%" class="hand" @click="indoor">
    <!--    content2 室外-->
    <img src="../../public/images/首页/轮播/首页室外机3.jpg" width="100%" height="100%" @click="outdoor">
    <!--    content3 wifi机-->
    <img src="../../public/images/首页/轮播/WIfi机.jpg" width="100%" height="100%" class="hand" @click="">
    <!--    content4 4G-->
    <img src="../../public/images/首页/轮播/4G摄像头.jpg" width="100%" height="100%" class="hand" @click="FGIndoor">
    <!--    content5 低功耗-->
    <img src="../../public/images/首页/轮播/低功耗机.jpg" width="100%" height="100%" class="hand" @click="dghIndoor">
    <!--    content5 智能单品-->
<!--    <img src="../../public/images/首页/轮播/智驹智能单品Z60Q&#45;&#45;7.jpg" width="100%" height="100%" class="hand" @click="">-->


    <!--    <div class="content1">-->
    <!--      <div class="content1_title">-->
    <!--          <div class="content1_title_size">室内应用</div>-->
    <!--          <div class="content1_title_hand" @click="indoor">查看全部></div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <div class="grid" id="grid">-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->


    <!--    content2  室外应用-->

    <!--    <div class="content1">-->
    <!--      <div class="content1_title">-->
    <!--        <div class="content1_title_size">室外应用</div>-->
    <!--        <div class="content1_title_hand" @click="examine">查看全部></div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <div class="grid" id="grid">-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->

    <!--    content3  智能门铃-->
    <!--    <div class="content1">-->
    <!--      <div class="content1_title">-->
    <!--        <div class="content1_title_size">智能门铃</div>-->
    <!--        <div class="content1_title_hand">查看全部></div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <div class="grid" id="grid">-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--          <photocard img="https://image.eye4.cn/default%E2%80%94IPC.jpeg" link=""></photocard>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->


    <div class="style_foot_1">
      <div>
        <img src="../../public/images/首页/首页底部APP图.jpg">
      </div>
      <div>
        <img src="../../public/images/首页/首页底部云存储图.jpg">
      </div>
    </div>
    <!--    <foot></foot>-->
  </div>
</template>

<script>
import zjMenu from '@/components/zjMenu.vue'
import foot from '@/components/foot.vue'
import photocard from '../../src/components/photo-card.vue'

export default {
  name: 'Home',
  components: {
    zjMenu, photocard, foot
  },
  data() {
    return{
      bannerHeight:'770'
    }
  },
  mounted () {
    // this.setSize();
    // const that = this;
    // window.addEventListener('resize', function() {
    //   that.screenWidth = $(window).width();
    //   that.setSize();
    // }, false);
  },
  methods: {
    setSize () {
      this.bannerHeight = 940 / 2560 * this.screenWidth
      if(this.bannerHeight > 940) this.bannerHeight = 940
      if(this.bannerHeight < 360) this.bannerHeight = 360
    },
    //室内应用
    indoor() {
      this.$router.push('/indoor')
    },
    //室外
    outdoor() {
      this.$router.push('/outdoor')
    },
    //4G
    FGIndoor() {
      this.$router.push('/4GIndoor')
    },
    //低功耗
    dghIndoor() {
      this.$router.push('/dghIndoor')
    },
    //公司简介
    companyIntro() {
      this.$router.push('/companyIntro')
    }
  }
}
</script>
<style scoped>
.kuan{
  min-width: 1200px;
}
.hand:hover {
  cursor: pointer;
}

.content1 {
  padding-top: 40px;
  width: 80%;
  height: 700px;
  margin: auto;
  /*background: #cd6969;*/
  border-bottom: 1px solid #42b983;
}

.content1_title {
  display: flex;
  justify-content: space-between;
}

.content1_title_size {
  font-size: 24px
}

.style_foot_1 {
  margin-top: 150px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.style_foot_1 div:nth-child(1) {
  padding-right: 10px;
}

.style_foot_1 div:nth-child(2) {
  padding-left: 10px;
}

.content1_title_hand:hover {
  cursor: pointer;
  color: #8bb9dc;
}


#grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-column-gap: 130px;
  grid-row-gap: 130px;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*max-width: 800px;*/
  /*display:flex;*/
  /*justify-content: space-evenly;*/
}

#grid .card {
  background-color: #ccc;
  width: 150px;
  height: 150px;
  transition: all 0.1s ease;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  overflow: hidden;
  cursor: pointer;
}

#grid .card:hover {
  -webkit-transform: scale(2);
  transform: scale(2);
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

#grid .card:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#grid .card .reflection {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  transition: all 0.1s ease;
  opacity: 0;
  mix-blend-mode: soft-light;
}

#grid .card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: grayscale(0.65);
  filter: grayscale(0.65);
  transition: all 0.3s ease;
}

.grid {
  padding-top: 100px;
}
</style>
